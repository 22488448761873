import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, UrlTree } from '@angular/router';
import { UrlService } from '@services/auth-services/url.service';

@Injectable({
  providedIn: 'root',
})
export class ActionRedirectGuard implements CanActivate {
  constructor(private urlService: UrlService) {}

  canActivate(route: ActivatedRouteSnapshot, state: any): boolean | UrlTree {
    const mode = route.queryParams['mode'];
    const continueUrl = route.queryParams['continueUrl'];
    const url = new URL(continueUrl);
    const subdomain = url.searchParams.get('subdomain');

    switch (mode) {
      case 'resetPassword':
        return this.urlService.redirectToPath(
          subdomain,
          this.withQueryString('auth/reset-password', route.queryParams)
        );
    }

    return false;
  }

  private withQueryString(url: string, params: Params) {
    const queryString = this.buildQueryString(params);
    return [url, queryString].join('?');
  }

  private buildQueryString(params: { [key: string]: any }): string {
    const queryParts = Object.entries(params).map(
      ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );

    return queryParts.length ? `${queryParts.join('&')}` : '';
  }
}
